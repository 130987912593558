<template>
  <div id="app" class="container">
    <button class="toggle-btn" @click="toggleView">
      <i :class="viewMode === 'appearance' ? 'fas fa-edit' : 'fas fa-eye'"></i>
      {{ viewMode === 'appearance' ? '编辑数据' : '查看外观' }}
    </button>

    <AppearanceView v-if="viewMode === 'appearance'" :events="events" />
    <DataView v-else :events="events" @updateEvent="updateEvent" @moveEvent="moveEvent" @deleteEvent="deleteEvent" @addEvent="addEvent" />
  </div>
</template>

<script>
import AppearanceView from './components/AppearanceView.vue'
import DataView from './components/DataView.vue'

export default {
  name: 'App',
  components: {
    AppearanceView,
    DataView
  },
  data() {
    return {
      viewMode: 'appearance',
      events: [
        {
          date: new Date(2023, 8, 19),
          icon: 'fa-check-circle',
          title: '备案通过，官网上线',
          details: '在知名之前，这里记录公司发展的时间线'
        },
        {
          date: new Date(2023, 8, 14),
          icon: 'fa-server',
          title: '在腾讯云买服务器，域名备案',
          details: '开发能有服务器用了，可喜可贺'
        },
        {
          date: new Date(2023, 7, 18),
          icon: 'fa-building',
          title: '公司成立',
          details: '折腾之路开始...'
        },
        {
          date: new Date(2022, 11, 25),
          icon: 'fa-robot',
          title: '人工智能项目启动',
          details: '利用先进的人工智能技术,为客户提供高效的解决方案。'
        },
        {
          date: new Date(2022, 7, 15),
          icon: 'fa-code',
          title: '网站开发阶段',
          details: '专业的开发团队正在努力开发一个功能强大、界面友好的网站。'
        },
        {
          date: new Date(2022, 4, 1),
          icon: 'fa-lightbulb',
          title: '创业点子形成',
          details: '通过头脑风暴和市场调研,我们形成了一个创新的商业点子。'
        }
      ]
    }
  },
  methods: {
    toggleView() {
      this.viewMode = this.viewMode === 'appearance' ? 'data' : 'appearance';
    },
    updateEvent(index, updatedEvent) {
      this.$set(this.events, index, updatedEvent)
    },
    moveEvent(oldIndex, newIndex) {
      const event = this.events.splice(oldIndex, 1)[0]
      this.events.splice(newIndex, 0, event)
    },
    deleteEvent(index) {
      this.events.splice(index, 1)
    },
    addEvent() {
      this.events.unshift({
        date: new Date(),
        icon: 'fa-plus',
        title: '',
        details: ''
      })
    }
  }
}
</script>

<style src="./styles.css"></style>