<!-- DataView.vue -->
<template>
  <div>
    <h1>编辑数据</h1>

    <button class="control-btn" @click="addEvent"><i class="fas fa-plus"></i> 新增事件</button>

    <div v-for="(event, index) in events" :key="index" class="event-item">
      <div class="event-box">
        <div class="form-group">
          <label>标题</label>
          <input type="text" v-model="event.title" maxlength="30" class="input-narrow" />
        </div>
        <div class="form-group">
          <label>日期</label>
          <input type="date" v-model="eventDate[index]" class="date-input" />
        </div>
        <div class="form-group">
          <label>详情</label>
          <textarea v-model="event.details" maxlength="500" rows="2" class="input-narrow"></textarea>
        </div>
        <div class="form-group">
          <label>图标</label>
          <div class="icon-select">
            <div class="selected-icon" @click="toggleIconList(index)">
              <i :class="['fas', 'fa-2x', event.icon]"></i>
            </div>
            <div v-if="showIconLists[index]" class="icon-list">
              <div v-for="icon in icons" :key="icon" class="icon-option" @click="selectIcon(index, icon)">
                <i :class="['fas', 'fa-2x', icon]"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="event-controls">
          <button class="control-btn" @click="moveUp(index)" :disabled="index === 0">
            <i class="fas fa-arrow-up"></i>
          </button>
          <button class="control-btn" @click="moveDown(index)" :disabled="index === events.length - 1">
            <i class="fas fa-arrow-down"></i>
          </button>
          <button class="control-btn danger" @click="deleteEvent(index)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "DataView",
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: [
        "fa-check-circle",
        "fa-server",
        "fa-building",
        "fa-robot",
        "fa-code",
        "fa-lightbulb",
        "fa-chart-line",
        "fa-users",
        "fa-trophy",
        "fa-laptop-code",
        "fa-rocket",
        "fa-handshake",
        "fa-money-bill-alt",
        "fa-bullhorn",
        "fa-globe",
        "fa-comments",
        "fa-briefcase",
        "fa-pencil-ruler",
        "fa-clipboard-check",
        "fa-box-open",
        "fa-truck",
        "fa-warehouse",
        "fa-shopping-cart",
        "fa-credit-card",
        "fa-calculator",
        "fa-chart-pie",
        "fa-chart-bar",
        "fa-file-contract",
        "fa-user-tie",
        "fa-graduation-cap",
      ],
      showIconLists: [],
      eventDate: [],
    };
  },

  watch: {
    eventDate: {
      handler(newDates) {
        newDates.forEach((date, index) => {
          const [year, month, day] = date.split("-");
          const updatedEvent = { ...this.events[index], date: new Date(year, month - 1, day) };
          this.$emit("updateEvent", index, updatedEvent);
        });
      },
      deep: true,
    },
  },
  created() {
    this.showIconLists = this.events.map(() => false);
    this.eventDate = this.events.map((event) => this.formatDate(new Date(event.date)));
  },
  methods: {
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    toggleIconList(index) {
      this.showIconLists = this.showIconLists.map((show, i) => (i === index ? !show : false));
    },
    selectIcon(index, icon) {
      const updatedEvent = { ...this.events[index], icon };
      this.$emit("updateEvent", index, updatedEvent);
      this.toggleIconList(index);
    },
    moveUp(index) {
      if (index > 0) {
        this.$emit("moveEvent", index, index - 1);
      }
    },
    moveDown(index) {
      if (index < this.events.length - 1) {
        this.$emit("moveEvent", index, index + 1);
      }
    },
    deleteEvent(index) {
      this.$emit("deleteEvent", index);
    },
    addEvent() {
      const newEvent = {
        date: new Date(),
        icon: "fa-plus",
        title: "",
        details: "",
      };
      this.$emit("addEvent", newEvent);
      this.showIconLists.push(false);
      this.eventDate.push(new Date());
    },
  },
};
</script>

<style>
.event-box {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 1rem 0;
  background-color: #f8f8f8;
}

.form-group {
  margin-bottom: 1rem;
}

.input-narrow {
  max-width: 300px;
}

.date-input {
  max-width: 200px;
}

.event-controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.control-btn {
  background-color: #47a3da;
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-btn.danger {
  background-color: #dc3545;
}

.control-btn i {
  margin: 0 0.2rem;
}
.icon-select {
  position: relative;
}

.selected-icon {
  cursor: pointer;
}

.icon-list {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}

.icon-option {
  cursor: pointer;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
