<!-- AppearanceView.vue -->
<template>
  <div>
    <h1>xx公司大事件</h1>
    <ul class="timeline">
      <li v-for="(event, index) in events" :key="index">
        <div class="time">
          <span>{{ formatDate(event.date, 'year') }}</span>
          <span>{{ formatDate(event.date, 'monthDay') }}</span>
        </div>
        <i :class="['icon', 'fas', event.icon]"></i>
        <div class="label">
          <h2>{{ event.title }}</h2>
          <p>{{ event.details }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppearanceView',
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatDate(dateString, type) {
      const date = new Date(dateString)
      const options = {
        month: 'long',
        year: 'numeric',
        day: 'numeric'
      }
      const formattedDate = date.toLocaleString('zh-CN', options)
      if (type === 'year') {
        return formattedDate.substr(0,5)
      } else if (type === 'monthDay') {
        return formattedDate.substr(5,30)
      }
    }
  }
}
</script>